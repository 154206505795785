import { graphql } from 'gatsby';
import * as React from 'react';
import { Query } from 'src/graphql-types';

import { Message } from '../components/ContentBox';
import Layout from '../layout';

export const query = graphql`
  query AddOnItemsQuery {
    allSanityAddons {
      edges {
        node {
          title
          blurb {
            price
            name
            description
          }
        }
      }
    }
  }
`;

interface PriceRowSimpleProps {
  title: string;
  extra: string;
  price: number;
  index: number;
}

const PriceRowSimple = ({
  title,
  extra,
  price,
  index,
}: PriceRowSimpleProps) => {
  const border = index > 1 ? 'border-t' : 'border-none';

  return (
    <p
      className={`flex items-center py-3 mb-0 ${border} border-gray-100 last:border-0`}
    >
      <span className="flex flex-col">
        <span>{title}</span>
        {extra && <span className="text-sm opacity-50">{extra}</span>}
      </span>
      <span className="flex-none pl-6 ml-auto text-xl text-right">
        ${price}
        <span className="block m-0 text-xs opacity-25">plus tax</span>
      </span>
    </p>
  );
};

interface RenderRowProps {
  price: number;
  name: string;
  description: string;
}

const renderRow = (
  { price, name, description }: RenderRowProps,
  index: number,
) => {
  return (
    <PriceRowSimple
      key={name}
      title={name}
      extra={description}
      price={price}
      index={index}
    />
  );
};

const Page = ({ data }: { data: Query }) => {
  const { title, blurb } = data.allSanityAddons.edges[0].node;

  return (
    <Layout title="Add-on items">
      <div className="w-full p-5 bg-white md:p-10" style={{ maxWidth: 1200 }}>
        <h1>{title}</h1>
        <div className="grid flex-col gap-5 mb-8 md:grid-cols-2 gap-y-0">
          {blurb.map(renderRow)}
          <Message className="mt-4">
            Products are not made in an allergy-free environment.
          </Message>
          <Message className="mt-4">
            All Ingredients are subject to change depending on freshness and
            availability at time of order.
          </Message>
        </div>
      </div>
    </Layout>
  );
};

export default Page;
