import cx from 'classnames';
import React, { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

export const ContentBox = ({ children }: Props) => {
  return (
    <>
      <div
        className="flex flex-col w-full p-5 bg-white md:p-10"
        style={{ maxWidth: 1200 }}
      >
        <div className="flex flex-col w-full space-x-0 space-y-10 bg-white md:flex-row md:space-x-10 md:space-y-0">
          {children}
        </div>
        <AllergyMessage />
      </div>
    </>
  );
};

export const Split = ({ children }: Props) => {
  return <div className="w-full md:w-1/2">{children}</div>;
};

export const Title = ({ children }: Props) => {
  return <h1>{children}</h1>;
};

interface MessageProps {
  children: ReactNode;
  className?: string;
}

export const Message = ({ children, className }: MessageProps) => {
  const classes = cx(
    className,
    'flex items-center p-3 mb-4 text-sm font-medium text-white bg-black rounded',
  );
  return (
    <div className={classes}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="flex-none mr-2 opacity-50"
      >
        <circle cx="12" cy="12" r="10" />
        <line x1="12" y1="8" x2="12" y2="12" />
        <line x1="12" y1="16" x2="12.01" y2="16" />
      </svg>
      {children}
    </div>
  );
};

export const AllergyMessage = () => {
  return (
    <div className="flex flex-col items-center px-3 pt-3 mt-10 font-medium bg-gray-200 rounded">
      <p>Products are not made in an allergy-free environment.</p>
      <p>
        All Ingredients are subject to change depending on freshness and
        availability at time of order.
      </p>

      <p>
        We’re happy to accommodate vegetarian, gluten-free, dairy-free and
        vegan, Please contact for pricing!
      </p>
    </div>
  );
};
